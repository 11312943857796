<template>
    <div class="user_agreement_container">
        <div v-html="htmltext" ></div>
    </div>
</template>
<script>
import { getUrlParam } from '@/config/utils'
import {basic} from '../../config/request'
export default {
    name: 'userAgreement',
    data() {
        return {
            xy:'',
            htmltext:''
        }
    },
    methods: {
        getXieyi(){
            basic.getXy({
xy:this.xy,
devices:'ALL',
rich_text:true
            }).then(res=>{
console.log(res);
if(res.code==200){
    this.htmltext=this.$showHtml(res.data[0].richText)
}
            })
        }
    },
    created() {
    },
    mounted(){
        if (this.$route.query.xy) {
      this.xy = this.$route.query.xy;
    }
    this.getXieyi()
    }
}
</script>
<style scoped>
    .user_agreement_container {
      width: 60%;
        padding: 30px;
        margin: 0 auto;
        text-align: left;
       
    } 
   ::v-deep span{
        display: unset;
    }
</style>